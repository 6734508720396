import React from "react"
import Layout from "../components/layout/Layout.js"
import { Row, BlogRow, TrainingRow } from "../components/row/Row.js"
import { graphql, useStaticQuery } from "gatsby"
import { Column50, TrainingColumn50 } from "../components/column/Column"
import { colors } from "../styles/colors"
import { Title, Heading } from "../components/title/Title"
import { TextMedium } from "../components/text/Text"
import { Section } from "../components/section/Section"
import { Snippet } from "../components/snippet/Snippet.js"
import Seo from "../components/seo"
import featuredImage from '../static/undraw_startup_life_2du2.png'
import authorImg from '../static/authors/andreeamacoveiciuc.jpeg'
import img9 from "../static/undraw_podcast_q6p7.png"
import BlogSubscribe from "../components/blogSubscribe/BlogSubscribe.js"

const BlogPosts = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPost(sort: { fields: date, order: DESC }) {
        edges {
          node {
            title
            id
            slug
            date(formatString: "Do MMMM, YYYY")
            featuredImage {
                gatsbyImageData(
                  width: 1200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
                resize(height: 630, width: 1200) {
                  src
                }
            }
            excerpt
            author {
              ... on ContentfulPerson {
                author
              }
            }
            description {
              childMarkdownRemark {
                timeToRead
                html
              }
            }
            content {
              raw
            }
          }
        }
        totalCount
      }
      contentfulPerson {
        author
      }
      site {
        siteMetadata {
          title
          siteUrl
        }
      }
    }
  `)

  const auth = `${data.contentfulPerson.author}`

  return (
    <Layout>
      <Seo title="Blog" 
      description="We share our knowledge through clearly-written, hands-on articles. We offer practical 
      advice and we work mostly with companies in the SaaS, e-commerce, health and marketing niches."
      image={featuredImage}
      featuredImage={featuredImage} />

        <BlogSubscribe/>

        <Section isCentered>
        <Title isPrimary title="Latest articles" />
        <Row isCentered>
          <TextMedium
            textMedium={`${data.allContentfulPost.totalCount} posts to read`}
          />
        </Row>
        <Row isCentered>
          {data.allContentfulPost.edges.map(({ node }) => (
            <Column50 key={`${node.id}`}>
              <Snippet
                snippetTitle={`${node.title}`}
                snippetText={`${node.excerpt}`} 
                authorName="Andreea Macoveiciuc"
                authorTitle="Content Strategist"
                avatarImg={authorImg}
                snippetImage={node.featuredImage.resize.src}
                href={`/blog/${node.slug}`}
                anchorText="Continue reading"
                readTime={`${node.description.childMarkdownRemark.timeToRead} min read`}
                publishDate={`${node.date}`} 
              />
            </Column50>
          ))}
        </Row>
      </Section>
    </Layout>
  )
}

export default BlogPosts
