import styled from "styled-components"
import { colors } from "../../styles/colors.js"
import { sizes, device} from "../../styles/sizes"
import { fontSizes } from "../../styles/typography"
import { StyledH3 } from "../title/style"

export const SnippetWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: ${props => (props.isCentered ? "center" : "flex-start")};
  align-items: center;
  margin-bottom: ${sizes.sizeXL};
  margin-left: auto;
  margin-right: auto;
  padding: ${sizes.sizeXXL} ${sizes.sizeXXL};
  border: 2px solid ${colors.colorTextLightPurple};
  border-radius: 8px;
  background-color: ${props =>
    props.isLight ? `${colors.colorTextLightest}` : `${colors.colorWhite}`};
  color: ${colors.colorTextDark};
  width: auto;

  @media ${device.tablet} {
    padding: ${sizes.sizeXXL} ${sizes.sizeXXXXL};
  }

  @media ${device.laptop} {
    padding: ${sizes.sizeXXL} ${sizes.sizeXXXXL};
  }

  @media ${device.desktop} {
    padding: ${sizes.sizeXXL} ${sizes.sizeXXXXL};
  }
`

export const SnippetTitle = styled(StyledH3)`
  color: ${colors.colorTextPurple} !important;
  justify-content: ${props => (props.isCentered ? "center" : "flex-start")};
  font-size: ${fontSizes.fontL};
  margin-bottom: ${sizes.sizeS};
  line-height: ${fontSizes.fontXL};
`

export const SnippetText = styled.p`
  color: ${colors.colorTextPurple} !important;
  font-size: ${fontSizes.fontM};
  margin-bottom: ${sizes.sizeS};
  line-height: ${fontSizes.fontL};
`

export const SnippetImage = styled.img`
  object-fit: cover;
  object-position: center center;
  height: 300px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: ${sizes.sizeS};
`

export const AuthorWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0;
  width: 100%;
`

export const SnippetAuthor = styled.div`
  display: flex;
  flex-flow: column wrap;
`

export const AuthorName = styled.p`
  font-size: ${fontSizes.fontS};
  color: ${colors.colorTextPurple};
  margin-bottom: 0;
  font-weight: 400;
  width: 200px;
`

export const AuthorTitle = styled.p`
  font-size: ${fontSizes.fontS};
`

export const AuthorAvatar = styled.img`
  height: 60px;
  width: 60px;
  border-radius: 100%;
  display: inline-block;
  overflow: hidden;
  object-fit: cover;
  margin-right: ${sizes.sizeM};
  margin-left: 0;
`
