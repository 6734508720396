import React from "react"
import {
  SnippetWrapper,
  SnippetText,
  SnippetTitle,
  AuthorWrapper,
  SnippetImage,
} from "./style.js"
import { Link } from "../link/Link"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock } from "@fortawesome/free-solid-svg-icons"
import { Author, DetailsWrapper } from "../author/Author"
import { colors } from "../../styles/colors.js"
import { TextSmall } from "../text/Text"

export const Snippet = ({ ...props }) => (
  <SnippetWrapper isLight={props.isLight} isCentered={props.isCentered}>
    <SnippetImage src={props.snippetImage} alt={props.imageAlt} />
    <SnippetTitle isCentered={props.isCentered}>{props.snippetTitle} </SnippetTitle>
    <AuthorWrapper>
      <DetailsWrapper isCentered>
        <TextSmall textSmall={`Written on ${props.publishDate} |`} />
        <FontAwesomeIcon
          icon={faClock}
          size="1x"
          style={{
            padding: "0 0.5rem",
            verticalAlign: "middle",
            margin: "14px 0 16px",
          }}
          color={colors.colorTextLightPurple}
        />
        <TextSmall textSmall={`${props.readTime} min read`} />
      </DetailsWrapper>
    </AuthorWrapper>
    <SnippetText>{props.snippetText}</SnippetText>
    <Link
      isCentered
      anchortext={props.anchorText}
      href={props.href}
      isPrimary={props.isPrimary}
    />
  </SnippetWrapper>
)
